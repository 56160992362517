import { render, staticRenderFns } from "./doctorAbilityList.vue?vue&type=template&id=1f645116"
import script from "./doctorAbilityList.vue?vue&type=script&lang=js"
export * from "./doctorAbilityList.vue?vue&type=script&lang=js"
import style0 from "./doctorAbilityList.vue?vue&type=style&index=0&id=1f645116&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports