<template>
    <el-card shadow="never" class="aui-card--fill">
        <div class="mod-demo__syssmslog">
            <el-form
                :inline="true"
                :model="dataForm"
                @keyup.enter.native="getDataList()"
            >
                <el-form-item>
                    <el-select
                        v-model="dataForm.inquiryType"
                        placeholder="问诊类型"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="dataForm.realName"
                        placeholder="账号真实名"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-form-item>
                        <el-select
                            v-model="dataForm.appCode"
                            placeholder="应用代码"
                        >
                            <el-option
                                v-for="item in CHANNELS"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form-item>

                <el-form-item>
                    <el-button @click="getDataList()">{{
                        $t('query')
                    }}</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="resetSearch()">{{
                        $t('cardList.reset')
                    }}</el-button>
                </el-form-item>
            </el-form>
            <el-table
                v-loading="dataListLoading"
                :data="dataList"
                border
                @selection-change="dataListSelectionChangeHandle"
                style="width: 100%"
            >
                <!-- // doctorId: "1407220631284441090"   医生ID
                // appCode: "MIAOINSTECH"            渠道
                // userName: "admin"                 账号
                // realName: "管理员"                 账号真实名
                // status: "0"                       是否在线
                // freeFlag: "N"                     空闲状态 -->

                <el-table-column
                    label="问诊类型"
                    header-align="center"
                    align="center"
                >
                    <template slot-scope="scope">
                        {{ handleName(scope.row) }}
                    </template>
                </el-table-column>

                <el-table-column
                    prop="appDesc"
                    label="渠道"
                    header-align="center"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="userName"
                    label="账号"
                    header-align="center"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="realName"
                    label="账号真实名"
                    header-align="center"
                    align="center"
                ></el-table-column>

                <el-table-column
                    prop="status"
                    label="是否在线"
                    header-align="center"
                    align="center"
                >
                    <template slot-scope="scope">
                        <div
                            :class="docStatus(scope.row.status)"
                            class="point"
                        ></div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="freeFlag"
                    label="空闲状态"
                    header-align="center"
                    align="center"
                >
                    <template slot-scope="scope">
                        <div
                            :class="docFreeFlag(scope.row.freeFlag)"
                            class="point"
                        ></div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="limit"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
        </div>
    </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module';
const apiUrl = {
    getChannelCode: '/sysapp/list', // 获取应用code
};
export default {
    mixins: [mixinViewModule],
    data() {
        return {
            dataForm: {
                appCode: '',
                realName: '',
                inquiryType: '',
            },
            CHANNELS: [],
            options: [
                {
                    label: '视频问诊',
                    value: 'V',
                },
                {
                    label: '图文问诊',
                    value: 'I',
                },
            ],
            mixinViewModuleOptions: {
                getDataListURL: '/trtc/abilityPage',
                // doctorId: "1407220631284441090"   医生ID
                // appCode: "MIAOINSTECH"            渠道
                // userName: "admin"                 账号
                // realName: "管理员"                 账号真实名
                // status: "0"                       是否在线
                // freeFlag: "N"                     空闲状态
                limit: 50,
                getDataListIsPage: true,
                // deleteURL: '/sys/smslog',
                deleteIsBatch: false,
            },
        };
    },
    mounted() {
        this.getChannelCode();
    },
    methods: {
        async getChannelCode() {
            const res = await this.$http.get(apiUrl.getChannelCode);

            const resData = res.data.data || [];
            this.CHANNELS = resData.map((item) => {
                return {
                    label: item.appDesc,
                    value: item.appCode,
                };
            });
        },
        docStatus(status) {
            return status == '0' ? 'off' : 'on';
        },
        docFreeFlag(freeFlag) {
            return freeFlag == 'N' ? 'off' : 'on';
        },
        resetSearch() {
            this.dataForm = {
                appCode: '',
                realName: '',
                inquiryType: '',
            };
            this.getDataList();
        },
        handleName(row) {
            return row.inquiryType == 'V' ? '视频问诊' : '图文问诊';
        },
    },
};
</script>
<style lang="scss">
.point {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.on::before {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: 0 0 3px 3px #eee;
    background-color: rgb(93, 190, 93);
}
.off::before {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: 0 0 3px 3px #eee;
    background-color: red;
}
</style>
